import { FormEventHandler } from 'react';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { Button } from 'components/UI/Button/Button';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { fieldRequiredMessage, incorrectDeviceIdMessage } from 'assets/messages/errorMessages';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { StyledRadioLabel } from 'components/UI/RadioLabel/StyledRadioLabel';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';

export interface FormInput {
  deviceId: string;
  mapType: string;
}
export interface FormErrors {
  deviceId?: FieldError;
  mapType?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholders?: Record<string, string>;
}

export const MapFilesForm = ({ onSubmit, register, errors, placeholders = {} }: Props) => (
  <form aria-label="form" onSubmit={onSubmit}>
    <StyledTextLabel htmlFor="deviceId">Serial Id:</StyledTextLabel>
    <StyledTextInput
      placeholder={placeholders.deviceId}
      {...register('deviceId', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        setValueAs: autofillG4ComboardId(),
        pattern: {
          value: /^\d{9}-\d{9}$/,
          message: incorrectDeviceIdMessage,
        },
      })}
    />
    {errors.deviceId && <StyledFormError>{errors.deviceId.message}</StyledFormError>}
    <StyledRadioLabel>
      <input {...register('mapType')} type="radio" name="mapType" value="siteMap" />
      Site Map
    </StyledRadioLabel>
    <StyledRadioLabel>
      <input {...register('mapType')} type="radio" name="mapType" value="zoneMap" />
      Zone Map
    </StyledRadioLabel>
    <StyledRadioLabel>
      <input {...register('mapType')} type="radio" name="mapType" value="generatedMap" />
      Generated Map
    </StyledRadioLabel>
    <Button type="submit">FETCH SVG</Button>
  </form>
);

export default MapFilesForm;
