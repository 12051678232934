import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import ToolSidebar from 'components/fragments/ToolSidebar/ToolSidebar';
import { useAppSelector, useAppDispatch } from 'hooks/hooks';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { handleRTKQueryError } from 'store/errorState/errorState';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import {
  useMowerEnvironmentCleanUpMutation,
  useMowerEnvironmentMutation,
} from 'store/api/tools-v1';
import { MowerEnvironmentRequest } from 'models/apiModels';
import { selectCurrentEnv, setCurrentEnvironment } from 'store/envState/envState';
import { roles } from '../../constants';
import { FormInput, MowerEnvironmentForm } from './MowerEnvironmentForm';
import { StyledMowerEnvironmentPresentation } from './styled';

export const MowerEnvironmentPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramDeviceId = searchParams.get('deviceId') ?? undefined;
  const currentEnvironmentFromHeader = searchParams.get('env') ?? 'qa';
  const [deviceId, setDeviceId] = useState<string | undefined>(paramDeviceId);
  const [okToMove, setOkToMove] = useState(true);
  const { role, username } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let applicationCurrentEnvironment = useAppSelector(selectCurrentEnv);

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  const [changeMowerEnvironment, { isLoading, isSuccess, error: queryError }] =
    useMowerEnvironmentMutation();
  const [changeMowerEnvironmentCleanUp, { isSuccess: isCleanUpInitiated }] =
    useMowerEnvironmentCleanUpMutation();

  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    if (formData.newEnvironment !== currentEnvironmentFromHeader) {
      setOkToMove(true);
      const oldParams = Object.fromEntries(searchParams);
      const params = { ...oldParams, deviceId: formData.deviceId };
      const mowerEnvironmentSessionRequest: MowerEnvironmentRequest = {
        deviceId: formData.deviceId,
        newEnvironment: formData.newEnvironment,
        requestedBy: username,
      };
      changeMowerEnvironment(mowerEnvironmentSessionRequest)
        .unwrap()
        .then(() => {
          applicationCurrentEnvironment = formData.newEnvironment;
          dispatch(setCurrentEnvironment(formData.newEnvironment));
        })
        .then(() => {
          changeMowerEnvironmentCleanUp(mowerEnvironmentSessionRequest)
            .unwrap()
            .then(() => {
              applicationCurrentEnvironment = currentEnvironmentFromHeader;
              dispatch(setCurrentEnvironment(currentEnvironmentFromHeader));
            });
        });

      setSearchParams(params, { replace: true });
      setDeviceId(formData.deviceId);
    } else {
      setOkToMove(false);
    }
  };

  useEffect(() => {}, [applicationCurrentEnvironment]);

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      deviceId: deviceId ?? '',
      newEnvironment: 'dev',
    },
  });

  return (
    <>
      <ToolSidebar>
        <MowerEnvironmentForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholder="123456789-123456789"
        />
      </ToolSidebar>
      <StyledPageWrapper>
        <StyledMowerEnvironmentPresentation>
          <h1>Change Mower Environment</h1>
          {!okToMove && (
            <h4>
              You must choose a new environment that is different from the one the mower is
              currently in.
            </h4>
          )}
          {isLoading && !queryError && (
            <div>
              <h4>Changing environment for {deviceId}...</h4>
              <h2> Please wait</h2>
            </div>
          )}
          {queryError && okToMove && (
            <div>
              <h2>Environment change failed!</h2>
              <p>Please try again later</p>
            </div>
          )}
          {!(isLoading && queryError) && isSuccess && okToMove && isCleanUpInitiated && (
            <div>
              <h3>Environment change successful!</h3>
            </div>
          )}
          <h4>Instructions</h4>
          <h4>Only for G4 mowers.</h4>
          <h4>Due to the mowers connection with proxyInCloud, this tool might not be successful every time.</h4>
          <p>
            To use this tool, choose the environment in the page top panel to which the mower is
            currently pointing.
          </p>
          <p>In the form, choose the environment to which you want the mower to change to.</p>
          <p> Click the Change button.</p>
        </StyledMowerEnvironmentPresentation>
      </StyledPageWrapper>
    </>
  );
};
