import { FieldError, UseFormRegister } from 'react-hook-form';
import { FormEventHandler } from 'react';
import { Button } from 'components/UI/Button/Button';
import {
  fieldRequiredMessage,
  incorrectDeviceIdMessage,
  incorrectDeviceNameMessage,
  incorrectEmailMessage,
  tooLongDeviceNameMessage,
} from 'assets/messages/errorMessages';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';
import { Pairing } from 'models/pairingModels';

export interface FormErrors {
  deviceId?: FieldError;
  email?: FieldError;
  deviceName?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<Pairing>;
  errors: FormErrors;
  placeholders?: Record<string, string>;
}

export const BackendPairingForm = ({ onSubmit, register, errors, placeholders = {} }: Props) => (
  <form aria-label="form" onSubmit={onSubmit}>
    <StyledTextLabel htmlFor="deviceId">Device Id:</StyledTextLabel>
    <StyledTextInput
      placeholder={placeholders.deviceId ?? ''}
      {...register('deviceId', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        setValueAs: autofillG4ComboardId(),
        pattern: {
          value: /^\d{9}-\d{9}$/,
          message: incorrectDeviceIdMessage,
        },
      })}
    />
    {errors.deviceId && <StyledFormError>{errors.deviceId.message}</StyledFormError>}
    <StyledTextLabel htmlFor="email">Account Email:</StyledTextLabel>
    <StyledTextInput
      placeholder={placeholders.email ?? ''}
      {...register('email', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        pattern: {
          value: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
          message: incorrectEmailMessage,
        },
      })}
    />
    {errors.email && <StyledFormError>{errors.email.message}</StyledFormError>}
    <StyledTextLabel htmlFor="deviceName">Device Name:</StyledTextLabel>
    <StyledTextInput
      placeholder={placeholders.deviceName ?? ''}
      {...register('deviceName', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        maxLength: {
          value: 21,
          message: tooLongDeviceNameMessage,
        },
        pattern: {
          // eslint-disable-next-line no-control-regex
          value: /^[\u0020-\u00FF]+$/,
          message: incorrectDeviceNameMessage,
        },
      })}
    />
    {errors.deviceName && <StyledFormError>{errors.deviceName.message}</StyledFormError>}
    <Button type="submit">Pair</Button>
  </form>
);

export default BackendPairingForm;
