import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { toolsApi } from 'store/api/tools-v1';
import { SubmitHandler, useForm } from 'react-hook-form';
import { StyledPageWrapper } from 'components/fragments/PageWrapper/styled';
import { useAppDispatch, useAppSelector } from 'hooks/hooks';
import { createRoleAccessChecker } from 'utils/userRolesUtils/userRolesUtils';
import { handleRTKQueryError } from 'store/errorState/errorState';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { MdContentCopy } from 'react-icons/md';
import { gdprInfoToString } from './clipboardUtils';
import {
  StyledGdprPresentation,
  StyledHeading,
  StyledSubHeading,
  StyledP,
  StyledSubP,
  FailedFetch,
  SuccessfullFetch,
  CopyToClipboardStyled,
} from './styled';
import GdprReportForm, { FormInput } from './GdprForm';
import ToolSideBar from '../../components/fragments/ToolSidebar/ToolSidebar';
import { roles } from '../../constants';
import { addIdToDevices, addIdToPositions } from '../../utils/renderUtils';

export const GdprPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const paramEmail = searchParams.get('email') ?? undefined;
  const [email, setEmail] = useState<string | undefined>(paramEmail);
  const [getGdprReport, { data, error: queryError, isLoading, isSuccess }] =
    toolsApi.useGetGdprReportMutation();

  const { role } = useAppSelector((state) => state.auth.session);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  if (role === roles.Unauthorized || !createRoleAccessChecker(role)(location.pathname)) {
    navigate('/', { replace: true });
  }

  // Wrapper to both set the deviceId (and trigger a new api request) and
  // to update the url
  const onSubmit: SubmitHandler<FormInput> = (formData) => {
    const oldParams = Object.fromEntries(searchParams);
    const params = { ...oldParams, email: formData.email };
    setSearchParams(params, { replace: true });
    setEmail(formData.email);
    getGdprReport({ email: formData.email });
  };

  useEffect(() => {
    if (queryError) {
      dispatch(handleRTKQueryError(queryError));
    }
  }, [queryError]);

  const {
    register,
    handleSubmit,
    formState: { errors: formErrors },
  } = useForm<FormInput>({
    defaultValues: {
      email,
    },
  });

  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      <ToolSideBar>
        <GdprReportForm
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={formErrors}
          placeholders={{ email: 'your@email.ad' }}
        />
      </ToolSideBar>
      <StyledPageWrapper>
        <h1>GDPR Report</h1>
        {isLoading && !queryError && <h3>Loading response for {paramEmail}...</h3>}
        {queryError && (
          <div>
            <FailedFetch>Failed to fetch GDPR report</FailedFetch>
          </div>
        )}
        {!(isLoading && queryError) && isSuccess && data && (
          <>
            <SuccessfullFetch>Fetched GDPR report successfully!</SuccessfullFetch>
            <CopyToClipboardStyled>
              <CopyToClipboard text={gdprInfoToString(data)} onCopy={onCopyText}>
                <span>
                  {isCopied ? (
                    <MdContentCopy size={30} color="green" />
                  ) : (
                    <MdContentCopy size={30} />
                  )}
                </span>
              </CopyToClipboard>
            </CopyToClipboardStyled>
            <StyledGdprPresentation>
              <StyledHeading>Name: </StyledHeading>
              <StyledP>{data.name}</StyledP>
              <br />
              <StyledHeading>Email: </StyledHeading>
              <StyledP>{data.email}</StyledP>
              <br />
              <StyledHeading>Country: </StyledHeading>
              <StyledP>{data.country}</StyledP>
              <br /> <br />
              <StyledHeading>Devices: </StyledHeading>
              {data.devices &&
                addIdToDevices(data.devices).map((device) => (
                  <div key={device.id}>
                    <hr></hr>
                    <StyledSubHeading> Name: </StyledSubHeading>
                    <StyledSubP>{device.name}</StyledSubP>
                    <StyledSubHeading>Central position (longitude, latitude):</StyledSubHeading>
                    <StyledSubP>
                      {device.centralPosition
                        ? `${device.centralPosition.longitude}, ${device.centralPosition.latitude}`
                        : 'No data stored'}
                      ,
                    </StyledSubP>
                    <StyledSubHeading>Positions (longitude, latitude):</StyledSubHeading>
                    {device.positions ? (
                      addIdToPositions(device.positions).map((item) => (
                        <StyledSubP key={item.id}>
                          {item.position.longitude}, {item.position.latitude}
                        </StyledSubP>
                      ))
                    ) : (
                      <StyledSubP> No data stored</StyledSubP>
                    )}
                  </div>
                ))}
            </StyledGdprPresentation>
          </>
        )}
      </StyledPageWrapper>
    </>
  );
};

export default GdprPage;
