import { PairingWithTimeOfDay } from 'models/pairingModels';
import { StyledPairingView } from './styled';

interface Props {
  pairing: PairingWithTimeOfDay;
}

export const PairingView = ({ pairing }: Props) => {
  return (
    <StyledPairingView>
      <hr />
      <p>{pairing.timeOfDay}</p> <br />
      Device Id: {pairing.pairing.deviceId} <br />
      Email: {pairing.pairing.email} <br />
      Device name: {pairing.pairing.deviceName} <br />
    </StyledPairingView>
  );
};
