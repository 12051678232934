import { PairingWithTimestamp } from 'models/pairingModels';
import { groupPairingsByDate } from 'utils/pairingUtils/pairingUtils';
import { PairingsAtDateView } from '../PairingsAtDateView';
import { StyledPairingHistory } from './styled';

interface Props {
  history: PairingWithTimestamp[];
}

export const PairingHistory = ({ history }: Props) => {
  const groupedPairings = groupPairingsByDate(history);
  return (
    <StyledPairingHistory>
      <h1>Pairing History</h1>
      {Object.keys(groupedPairings).map((date, i) => (
        <PairingsAtDateView
          key={date}
          date={date}
          pairings={groupedPairings[date]}
          expanded={i === 0}
        />
      ))}
    </StyledPairingHistory>
  );
};
