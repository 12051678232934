import { Device, GDPRinfo, LongLat } from 'models/apiModels';

function positionInfoToString(positions: LongLat[]): string {
  let info = '';
  positions.forEach((position) => {
    info += ` ${position.longitude}, ${position.latitude}\n`;
  });
  return info;
}

function deviceInfoToString(devices: Device[]): string {
  let info = '';
  devices.forEach((device) => {
    const centralPositionInfo = device.centralPosition
      ? ` ${device.centralPosition.longitude}, ${device.centralPosition.latitude}\n`
      : ' No data stored \n';

    const positionsInfo = device.positions
      ? positionInfoToString(device.positions)
      : ' No data stored \n';

    info += '-------------------------------------------------------------------\n';
    info += `Name:\n ${device.name}\nCentral position (longitude, latitude):\n${centralPositionInfo}Positions (longitude, latitude):\n${positionsInfo}`;
  });
  return info;
}

export function gdprInfoToString(data: GDPRinfo): string {
  const commonInfo = `Name: ${data.name}\nEmail: ${data.email}\nCountry: ${data.country}\nDevices:\n`;
  const deviceInfo = data.devices ? deviceInfoToString(data.devices) : ' No data stored \n';
  return commonInfo + deviceInfo;
}
