import { PairingWithTimeOfDay } from 'models/pairingModels';
import { useState } from 'react';
import { PairingView } from '../PairingView';
import { StyledCollapsibleContainer, StyledHeader, StyledPairingsAtDateView } from './styled';

interface Props {
  date: string;
  pairings: PairingWithTimeOfDay[];
  expanded: boolean;
}

export const PairingsAtDateView = ({ date, pairings, expanded }: Props) => {
  const [isOpen, setIsOpen] = useState(expanded);

  const toggleOpen: React.MouseEventHandler<HTMLDivElement> = (_event) => {
    setIsOpen(!isOpen);
  };

  return (
    <StyledPairingsAtDateView onClick={toggleOpen}>
      <StyledHeader open={isOpen}>
        <h4>{date}</h4>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 12">
          <g fill="#3D3D3C">
            {/* eslint-disable-next-line max-len */}
            <path d="M9.99999 8.32479L2.20405 0.528847C2.00879 0.333585 1.6922 0.333584 1.49694 0.528846L0.454047 1.57174C0.258785 1.767 0.258786 2.08358 0.454048 2.27885L9.64644 11.4712C9.8417 11.6665 10.1583 11.6665 10.3535 11.4712L19.5459 2.27885C19.7412 2.08358 19.7412 1.767 19.5459 1.57174L18.503 0.528846C18.3078 0.333584 17.9912 0.333584 17.7959 0.528846L9.99999 8.32479Z" />
          </g>
        </svg>
      </StyledHeader>
      <StyledCollapsibleContainer open={isOpen}>
        {pairings.map((pairing) => (
          <PairingView key={pairing.timeOfDay} pairing={pairing} />
        ))}
      </StyledCollapsibleContainer>
    </StyledPairingsAtDateView>
  );
};
