import { Button } from 'components/UI/Button/Button';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import { fieldRequiredMessage, incorrectDeviceIdMessage } from 'assets/messages/errorMessages';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { FormEventHandler } from 'react';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';

export interface FormInput {
  deviceId: string;
}
export interface FormErrors {
  deviceId?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const DeleteTwinForm = ({ onSubmit, register, errors, placeholder = '' }: Props) => (
  <form onSubmit={onSubmit}>
    <StyledTextLabel htmlFor="deviceId">Device Id:</StyledTextLabel>
    <StyledTextInput
      {...register('deviceId', {
        required: {
          value: true,
          message: fieldRequiredMessage,
        },
        setValueAs: autofillG4ComboardId(),
        pattern: {
          value: /^\d{9}-\d{9}$/,
          message: incorrectDeviceIdMessage,
        },
      })}
      placeholder={placeholder}
    />
    {errors.deviceId && <StyledFormError>{errors.deviceId.message}</StyledFormError>}
    <Button type="submit" id="Delete">
      Delete
    </Button>
  </form>
);
