import { Button } from 'components/UI/Button/Button';
import { StyledTextInput } from 'components/UI/InputElements/StyledTextInput';
import {
  fieldRequiredMessage,
  incorrectDeviceIdMessage,
  incorrectIprIdMessage,
} from 'assets/messages/errorMessages';
import { StyledTextLabel } from 'components/UI/TextLabel/StyledTextLabel';
import { StyledFormError } from 'components/UI/FormError/StyledFormError';
import { FieldError, UseFormRegister } from 'react-hook-form';
import { FormEventHandler, useState } from 'react';
import { autofillG4ComboardId } from 'utils/formsUtils/formsUtils';
import { StyledRadioLabel } from 'components/UI/RadioLabel/StyledRadioLabel';

export interface FormInput {
  deviceId: string;
}
export interface FormErrors {
  deviceId?: FieldError;
}
interface Props {
  onSubmit: FormEventHandler<HTMLFormElement>;
  register: UseFormRegister<FormInput>;
  errors: FormErrors;
  placeholder?: string;
}

export const FetchTwinForm = ({ onSubmit, register, errors, placeholder = '' }: Props) => {
  const [idFormat, setIdFormat] = useState<'serialId' | 'iprId'>('serialId');

  const handleFormatChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIdFormat(event.target.value as 'serialId' | 'iprId');
  };

  return (
    <form onSubmit={onSubmit}>
      <StyledTextLabel htmlFor="deviceId">Mower Id:</StyledTextLabel>
      <StyledRadioLabel>
        <input
          type="radio"
          value="serialId"
          checked={idFormat === 'serialId'}
          onChange={handleFormatChange}
        />
        Device Id
      </StyledRadioLabel>
      <StyledRadioLabel>
        <input
          type="radio"
          value="iprId"
          checked={idFormat === 'iprId'}
          onChange={handleFormatChange}
        />
        Ipr Id
      </StyledRadioLabel>
      <StyledTextInput
        {...register('deviceId', {
          required: {
            value: true,
            message: fieldRequiredMessage,
          },
          setValueAs: autofillG4ComboardId(),
          pattern: {
            value:
              idFormat === 'serialId'
                ? /^\d{9}-\d{9}$/
                : /^[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}$/,
            message: idFormat === 'serialId' ? incorrectDeviceIdMessage : incorrectIprIdMessage,
          },
        })}
        placeholder={
          placeholder ||
          (idFormat === 'serialId' ? '123456789-123456789' : 'a1a1a1a1-a1a1-a1a1-a1a1-a1a1a1a1a1a1')
        }
      />
      {errors.deviceId && <StyledFormError>{errors.deviceId.message}</StyledFormError>}
      <Button type="submit" id="view">
        View
      </Button>
      <Button type="submit" id="download">
        Download
      </Button>
    </form>
  );
};
