import { Device, LongLat } from '../models/apiModels';

export function addIdToPositions(positions: LongLat[]) {
  let counter: number = 0;
  const newArray = positions.map((item) => {
    counter += 1;
    return {
      id: counter,
      position: item,
    };
  });
  return newArray;
}

export function addIdToDevices(devices: Device[]) {
  let counter: number = 0;
  const newArray = devices.map((item) => {
    counter += 1;
    return {
      ...item,
      id: counter,
    };
  });
  return newArray;
}
